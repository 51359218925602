import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

//Import Blocks
import ExpertiseHeader from '../blocks/expertiseHeader.jsx';
import WhatWeDo from '../blocks/whatWeDo';
import leftTextRightImage from '../blocks/leftTextRightImage.jsx';
import CaseStudySplash from '../blocks/caseStudySplash/index';
import Recognition from '../blocks/awards';
import FooterPreface from '../blocks/footerPreface';

//Import Components
import BlankSpace from '../components/blankSpace';

const TestGround = () => (
  <Layout>
    <SEO title="Our Expertise" />
    {/*
    <ExpertiseHeader />
    <BlankSpace height="20px" />
    <WhatWeDo />
    <BlankSpace height="90px" mobileHeight="0px" />
    <leftTextRightImage />
    <CaseStudySplash />
    <BlankSpace height="120px" />
    <Recognition />
    <BlankSpace height="100px" mobileHeight="0px" />
<FooterPreface />*/}
  </Layout>
);

export default TestGround;
